import * as React from 'preact'

interface TalentPoolNetworkProps {
	title: string
	subtitle: string
	ctaText?: string
	ctaLink?: string
}

class TalentPoolNetwork extends React.Component<TalentPoolNetworkProps, {}> {
	public render() {
		const { title, subtitle, ctaText, ctaLink } = this.props
		
		return (
			<div className="content-section">
				<div className="container">
					<div className="bg-container">
						<div className="w-layout-grid grid-integrations">
							<div className="integration-wrap">
								<div className="integration-circle" style="background-image: url(/images/user-pic-6.jpg);"/>
								<div className="integration-circle-02" style="background-image: url(/images/user-pic-4.jpg);"/>
								<div className="integration-circle-03" style="background-image: url(/images/user-pic-1.jpg);"/>
								<div className="integration-circle-04" style="background-image: url(/images/user-pic-2.jpeg);"/>
								<div className="integration-circle-05" style="background-image: url(/images/user-pic-7.jpg);"/>
							</div>
							<div className="content-text-wrapper">
								<h1 className="content-h1" dangerouslySetInnerHTML={{ __html: title }}/>
								<p className="paragraph" dangerouslySetInnerHTML={{ __html: subtitle }}/>
								{
									ctaText && (
										<a href={ctaLink} className="link-arrow w-inline-block">
											<div className="arrow-text">{ctaText}</div><img src="/images/5e7adffc6e755a3d0ea7d53e_arow.svg" alt="" className="arrow"/>
										</a>
									)
								}
							</div>
						</div>
						<div className="bg-element bg-integrations"/>
					</div>
				</div>
			</div>
		)
	}
}

export default TalentPoolNetwork