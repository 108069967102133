import * as React from 'preact'
import StickyHeader from './StickyHeader'
import StickyFooter from './StickyFooter'

interface BaseSignupProps {}

class BaseSignup extends React.Component<BaseSignupProps, {}> {
	public render() {
		const { children } = this.props
		
		return (
			<div>
				<StickyHeader/>
				<div className="page-signup__inner">
					{children}
				</div>
				<StickyFooter/>
			</div>
		)
	}
}

export default BaseSignup