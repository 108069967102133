import * as React from 'preact'

import HeroInput from './HeroInput'

class HeroPlatform extends React.Component<{}, {}> {
	public render() {
		return (
			<div class="hero-platform">
				<div class="container">
					<div class="title-left">
						<h1 style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transform-style: preserve-3d;" class="display-heading">Learn how Otto can drive you sales</h1>
						<div style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transform-style: preserve-3d;" class="hero-details">Integrate our plugins into any website or social platform</div>
					</div>
				</div>
			</div>
		)
	}
}

export default HeroPlatform