import * as React from 'preact'
import CalendlyLink from './CalendlyLink'
import Link from './Link'

class FooterCTA extends React.Component<{}, {}> {
	public render() {
		return (
			<div className="cta-section">
				<div className="container">
					<div className="cta-banner">
						<div className="cta-container">
							<h2 className="cta-h2"><span className="text-span">Ready to dive in?<br/></span>Find the right CxO for you.</h2>
							<div className="cta-button-wrap">
								<Link href="/signup" className="button-start w-button">Get Started</Link>
								<CalendlyLink link="https://calendly.com/urbanfounders/discovery-call">
									<button className="button-white w-button">Request a Demo</button>
								</CalendlyLink>
							</div>
						</div>
					</div>
				</div>
				<div className="fixed-background"/>
			</div>
		)
	}
}

export default FooterCTA