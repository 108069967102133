import * as React from 'preact'
import BaseSignup from '../containers/BaseSignup'

class ConfirmEmail extends React.Component<{}, {}> {
	public render() {
		return (
			<BaseSignup>
				<div className="page-signup__form__wrap">
					<div>Confirm Email</div>
				</div>
				
				<div className="page-signup__graphics__wrap">
				
				</div>
			</BaseSignup>
		)
	}
}

export default ConfirmEmail