import * as React from 'preact'
import { connect } from "unistore/preact"

import BaseSignup from '../containers/BaseSignup'

import { RootState } from '../store'
import { State as AuthenticateUserState } from '../store/authenticate'
import { parse as parseQueryString } from "querystring"
import Input, { InputType } from '../components/Input'
import PasswordInput, { PasswordInputType } from '../components/PasswordInput'
import LoadingButton from '../components/LoadingButton'
import Link from '../components/Link'
import { loginUser } from '../actions/auth'


interface SigninProps {
	authenticateState: AuthenticateUserState
	loginUser: any
}

interface SigninState {
	email: string
	password: string
}

class Signin extends React.Component<SigninProps, SigninState> {
	public componentDidMount() {
		document.title = "Sign In | Urban Founders"
	}
	
	public render() {
		const { authenticateState } = this.props
		const { email, password } = this.state
		
		return (
			<div class="page page-signup">
				<div className="page-signup__form__wrap">
					<BaseSignup>
						<div className="register-form">
							<div className="register-form__inner">
								<div className="register-form__title">
									<h2>Signin to your account</h2>
								</div>

								<div className="register-form__form">
									<form action="#" onSubmitCapture={this.submitForm.bind(this)} formNoValidate={true}>
										<Input
											name="email"
											placeholder="Enter Email Address"
											required={true}
											value={email}
											type={InputType.EMAIL}
											onChange={(v: string) => this.validateForm({ email: v })}/>
										
										<Input
											name="password"
											placeholder="Enter Password"
											required={true}
											value={password}
											type={InputType.PASSWORD}
											onChange={(v: string) => this.validateForm({ password: v })}/>
										
										<div className="register-form__submit__wrap">
											<div className="register-form__submit">
												<LoadingButton
													status={authenticateState.status}
													type="submit"
													className="button-submit w-button">
													Sign In
												</LoadingButton>
											</div>
											<div className="register-form__forgot-password">
												<Link href="/forgot-password">Forgot Password ?</Link>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</BaseSignup>
				</div>
				<div className="page-signup__graphics__wrap" style="background-image: url(/images/signup-illustration.svg);">
				
				</div>
			</div>
		)
	}
	
	private validateForm(modifiedState?: any) {
		const {email, password} = this.state
		this.setState({...this.state, ...modifiedState, isValid: (email && password)})
	}
	
	private submitForm(e: Event) {
		const { email, password } = this.state
		
		e.preventDefault()
		
		const params = parseQueryString(window.location.search.replace('?', ''))
		// this.props.loginUser(email, password, params.redirect)
	}
}

const mapStateToProps = (state: RootState) => ({
	authenticateState: state.authenticate
})

export default connect(mapStateToProps, {
	loginUser
})(Signin)