import { ActionTypeStates } from '../constants/action-types'

export interface State {
	status: ActionTypeStates
	error: any
}

export const initialState: State = {
	status: ActionTypeStates.NONE,
	error: null
}