import * as React from 'preact'
import Router, { Route } from 'preact-router'
import { Provider } from 'unistore/preact'

import App from './App'

import Homepage from '../pages/Homepage'
import Platform from '../pages/Platform'
import Memberships from '../pages/Memberships'
import Company from '../pages/Company'
import Login from '../pages/Login'
import Register from '../pages/Register'
import GettingStarted from '../pages/GettingStarted'
import Dashboard from '../pages/Dashboard'
import ForgotPassword from '../pages/ForgotPassword'
import ConfirmEmail from '../pages/ConfirmEmail'

import Redirect from '../components/Redirect'

import store from '../store'
import DashboardActivity from '../pages/DashboardActivity'
import DashboardSchedule from '../pages/DashboardSchedule'
import DashboardBilling from '../pages/DashboardBilling'
import DashboardTeam from '../pages/DashboardTeam'
import DashboardInsights from '../pages/DashboardInsights'
import Profile from '../pages/Profile'
import NotFound from '../pages/NotFound'
import TermsOfUse from '../pages/TermsOfUse'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import Contact from '../pages/Contact'
import Signin from '../pages/Signin'


const PrivateRoute = ({redirectPath, component: Component, ...rest}: any) => {
	if (window.localStorage.uf_token) {
		return <Component {...rest} />
	} else {
		return <Redirect to={'/signin?redirect=' + rest.path} />
	}
}

const PublicRoute = ({redirectPath, component: Component, ...rest}: any) => {
	if (!window.localStorage.uf_token) {
		return <Component {...rest} />
	} else {
		return <Redirect to={`/dashboard?sourceRedirect=${rest.path}`}/>
	}
}

const RegularRoute = ({component: Component, ...rest}: any) => (
	<Component {...rest} />
)

interface RootProps {}

class Root extends React.Component<RootProps, {}> {
	public render() {
		return (
			<Provider store={store}>
				<App>
					<Router onChange={() => window.scrollTo(0, 0)}>
						<RegularRoute path="/" component={Homepage}/>
						<Platform path="/platform" />
						<Contact path="/contact" />
						{/*<Company path="/company" />*/}
						{/*<Memberships path="/memberships" />*/}
						
						<PublicRoute path="/signin" component={Signin}/>
						<PublicRoute path="/signup" component={Register} />
						
						<PublicRoute path="/confirm-email" component={ConfirmEmail} />
						<PublicRoute path="/forgot-password" component={ForgotPassword} />
						
						{/*<PrivateRoute path="/getting-started/:path?" component={GettingStarted} />*/}
						
						<PrivateRoute path="/dashboard" component={Dashboard} />
						<PrivateRoute path="/dashboard/activity" component={DashboardActivity} />
						<PrivateRoute path="/dashboard/schedule" component={DashboardSchedule} />
						<PrivateRoute path="/dashboard/billing" component={DashboardBilling} />
						<PrivateRoute path="/dashboard/team" component={DashboardTeam} />
						<PrivateRoute path="/dashboard/insights" component={DashboardInsights} />
						
						<PrivateRoute path="/dashboard/my-profile" component={Profile} />
						
						<TermsOfUse path="/terms-of-service" />
						<PrivacyPolicy path="/privacy-policy" />
						
						<NotFound default />
					</Router>
				</App>
			</Provider>
		)
	}
}

export default Root