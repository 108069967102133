import createStore from 'unistore'

import * as AuthenticateState from './authenticate'
import * as RegisterState from './register'
import * as ForgotPasswordState from './forgotPassword'
import * as ResetPasswordState from './resetPassword'

export interface RootState {
	authenticate: AuthenticateState.State,
	register: RegisterState.State,
	forgotPassword: ForgotPasswordState.State,
	resetPassword: ResetPasswordState.State
}

const store = createStore({
	authenticate: AuthenticateState.initialState,
	register: RegisterState.initialState,
	forgotPassword: ForgotPasswordState.initialState,
	resetPassword: ResetPasswordState.initialState
})

export default store