import * as React from 'preact'
import DashboardHeader from './DashboardHeader'
import DashboardToolbar from './DashboardToolbar'
import DashboardActivity from './DashboardActvity'

interface BaseDashboardProps {}

class BaseDashboard extends React.Component<BaseDashboardProps, {}> {
	public componentDidMount() {
		// const Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
		(function(){
			var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
			s1.async=true;
			s1.src='https://embed.tawk.to/5e940a3835bcbb0c9ab062e7/default';
			s1.charset='UTF-8';
			s1.setAttribute('crossorigin','*');
			s0.parentNode.insertBefore(s1,s0);
		})();
	}
	
	public render() {
		const { children } = this.props
		
		return (
			<div className="page page-dashboard">
				<DashboardHeader/>
				
				<div className="page-dashboard__inner">
					<div className="page-dashboard__toolbar">
						<DashboardToolbar/>
					</div>
					
					<div className="page-dashboard__content">
						{children}
					</div>
					
					<div className="page-dashboard__activity">
						<DashboardActivity/>
					</div>
				</div>
				{/*	Copyright Notice*/}
			</div>
		)
	}
}

export default BaseDashboard