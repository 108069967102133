import * as React from 'preact'

import BasePublic from '../containers/BasePublic'

import Hero from '../components/Hero'
import ClientsLogos from '../components/ClientsLogos'
import Spotlight, { SpotlightOrientation } from '../components/Spotlight'
import PlatformFeatures from '../components/PlatformFeatures'
import TalentPoolNetwork from '../components/TalentPoolNetwork'
import FooterCTA from '../components/FooterCTA'
import Features from '../components/Features'

class Homepage extends React.Component<{}, {}> {
	public componentDidMount() {
		document.title = "CxO as a Service | Urban Founders"
	}
	
	public render() {
		return (
			<BasePublic>
				<Hero/>
				
				<TalentPoolNetwork
					title="A Global Talent Pool of Experts"
					subtitle="At Urban Founders we strongly believe and adopt a remote work ethic. Our growing pre-vetted talent
					pool, of <strong>10+</strong> CxOs come from across all continents and timezones. <br/><br/> Founders and CxOs
					spend time matching their values while we handle matching the merits."
					ctaText={"Meet our CxOs"}
					ctaLink="/signup"/>
					
				<Spotlight
					orientation={SpotlightOrientation.IMAGE_RIGHT_TEXT_LEFT}
					title="Pivot, Scale & <br/>Pay-as-you-Grow"
					subtitle="Our solutions are catered exclusively for early stage startups. We encourage our founders to be bold,
					flexible and pivot when needed. <br/> <br/> Urban Founders's Pay-as-you-Grow model lets founders scale their
					expenses with no long-term lockins."
					featuredImage="/images/scale-pivot-pay-image.png"
					ctaText="Get Started"
					ctaLink="/signup"
					sectionClasses="content-grid-02"/>
				
				<PlatformFeatures/>
				<Features/>
				<ClientsLogos/>
				<FooterCTA/>
			</BasePublic>
		)
	}
}

export default Homepage