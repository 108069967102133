import * as React from 'preact'

class Features extends React.Component<{}, {}> {
	public render() {
		return (
			<div class="content-section">
				<div class="container">
					<div class="bg-container bg-top">
						<div class="value-title-wrapper">
							<h2>Our CxOs specialize in</h2>
						</div>
						<div class="w-layout-grid features-grid">
							<div class="feature-card"><img src="/images/uf-industries-1.svg" alt="" class="value-icon"/>
								<p class="paragraph-large paragraph-bold">EdTech</p>
								<p>Technology (Hardware / Software) supporting teaching and learning.</p>
							</div>
							<div class="feature-card"><img src="/images/uf-industries-2.svg" alt="" class="value-icon"/>
								<p class="paragraph-large paragraph-bold">Smart Retail</p>
								<p>Everything from cashier less checkouts to innovative online e-commerce software.</p>
							</div>
							<div class="feature-card"><img src="/images/uf-industries-3.svg" alt="" class="value-icon"/>
								<p class="paragraph-large paragraph-bold">IoT & Hardware</p>
								<p>Smart, innovative and interconnected solutions for everyday needs.</p>
							</div>
						</div>
						<div class="bg-element bg-feature"/>
					</div>
				</div>
			</div>
		)
	}
}

export default Features