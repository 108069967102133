import { route } from 'preact-router'
import { parse as parseQueryString } from 'querystring'

import http, { rebuildHttp } from '../utils/httpService'
import {
	AUTH_API,
	CONFIRM_EMAIL_API,
	LOGIN_API,
	RECOVER_PASSWORD_API,
	REGISTER_API,
	RESET_PASSWORD_API
} from '../constants/api'
import { UserRecord } from '../interfaces/user'

import store, { RootState } from '../store'
import { ActionTypeStates } from '../constants/action-types'

/**
 * Authenticate user and redirect to
 *
 * @param state
 * @param redirectTo
 */
export const authenticateUser = async (state: RootState, redirectTo?: string) => {
	const { authenticate } = state
	
	try {
		const res = await doAuthenticate()
		authenticate.status = ActionTypeStates.SUCCESS
		authenticate.user = res
		
		if (redirectTo) { route(redirectTo) }
	} catch (error) {
		if (error) {
			const params = parseQueryString(window.location.search.replace('?', ''))
			if (params.sourceRedirect) { route(params.sourceRedirect as string) }
			await doLogoutUser()
		}
		
		authenticate.status = ActionTypeStates.FAILED
		authenticate.error = error
	}
	
	return { authenticate: { ...authenticate } }
}

/**
 * Helper function to retrieve the user data after authenticating using the token from local storage
 *
 * @returns {Promise<UserRecord>}
 */
export const doAuthenticate = () => {
	return new Promise<UserRecord>((resolve: any, reject: any) => {
		http.get(AUTH_API).then(response => {
			if (response.data.message) { reject(response) } else { resolve(response.data) }
		}).catch(error => reject(error))
	})
}

/**
 * Register the user
 *
 * @param state
 * @param {string} email
 * @param {string} password
 */
export const registerUser = async (state: RootState, email: string, password: string) => {
	const { register } = state
	store.setState({ register: { ...state.register, status: ActionTypeStates.INPROGRESS } })
	
	try {
		await doRegisterUser(email, password)
		register.status = ActionTypeStates.SUCCESS
	} catch (error) {
		register.status = ActionTypeStates.FAILED
		register.error = error
	}
	
	return { register: { ...register } }
}

/**
 * Helper function to register the user giving an email and password
 *
 * @param {string} email
 * @param {string} password
 * @returns {Response}
 */
const doRegisterUser = (email: string, password: string) => {
	return new Promise((resolve: any, reject: any) => {
		http.post(REGISTER_API, { email, password })
			.then(response => resolve())
			.catch(error => reject(error))
	})
}

/**
 * Confirm user email
 *
 * @param state
 * @param {string} email
 * @param {string} token
 */
export const confirmUserEmail = async (state: RootState, email: string, token: string) => {
	return doConfirmUserEmail(email, token)
		.then(() => authenticateUser(state))
}

/**
 * Helper function to register the user giving an email and password
 *
 * @param {string} email
 * @param {string} token
 * @returns {Response}
 */
const doConfirmUserEmail = (email: string, token: string) => {
	return new Promise((resolve: any, reject: any) => {
		http.post(CONFIRM_EMAIL_API, { email, token })
			.then(response => resolve())
			.catch(error => reject(error))
	})
}

/**
 * Login user
 *
 */
export const loginUser = (state: RootState, email: string, password: string, redirectTo?: string) => {
	store.setState({ authenticate: { ...state.authenticate, status: ActionTypeStates.INPROGRESS } })
	
	return doLoginUser(email, password)
		.then(() => authenticateUser(state, redirectTo))
		.catch(() => store.setState({ authenticate: { ...state.authenticate, status: ActionTypeStates.FAILED } }))
}

/**
 * Helper function to login user
 *
 * @returns {Promise>}
 */
const doLoginUser = (email: string, password: string) => {
	return new Promise<string>(async (resolve: any, reject: any) => {
		try {
			const response = await http.post(LOGIN_API, { email, password })
			const token = response.data.token
			window.localStorage.uf_token = token
			rebuildHttp()
			setTimeout(() => resolve(token), 5000)
		} catch (error) {
			reject(error)
		}
	})
}

/**
 * Logout user
 *
 */
export const logoutUser = (state: RootState, redirectTo?: string) => {
	return doLogoutUser()
		.then(() => authenticateUser(state, redirectTo))
}

/**
 * Helper function to login user
 *
 * @returns {Promise<any>}
 */
const doLogoutUser = () => {
	return new Promise((resolve: any) => {
		window.localStorage.removeItem('uf_token')
		rebuildHttp()
		resolve()
	})
}

/**
 * Authenticate the user via linkedin
 *
 */
export const authenticateLinkedin = (state: RootState, props: any, redirectTo?: string) => {
	return doAuthenticateLinkedin(props)
		.then(() => authenticateUser(state, redirectTo))
}

/**
 * Helper function to authenticate the user via linkedin
 *
 * @returns {Promise<any>}
 */
const doAuthenticateLinkedin = (props: any) => {
	return new Promise((resolve: any, reject: any) => {
		if (props.token) {
			const token = props.token
			window.localStorage.uf_token = token
			rebuildHttp()
			resolve(token)
		} else {
			reject()
		}
	})
}

/**
 * Reset the user password with the recovery token and new password
 *
 * @param state
 * @param token
 * @param email
 * @param password
 */
export const resetUserPassword = (state: RootState, token: string, email: string, password: string) => {
	return doResetUserPassword(token, email, password)
		.then(() => authenticateUser(state))
}

/**
 * Underlying utility function to reset the users password
 *
 * @param token
 * @param email
 * @param password
 */
const doResetUserPassword = (token: string, email: string, password: string) => {
	return new Promise((resolve: any, reject: any) => {
		http.post(RECOVER_PASSWORD_API, { token, email, password, password_confirmation: password })
			.then(response => {
				const loginToken = response.data.token
				window.localStorage.uf_token = loginToken
				rebuildHttp()
				resolve(loginToken)
			})
			.catch(error => reject(error))
	})
}

/**
 * Recover a users password by sending them a recovery email
 *
 * @param state
 * @param email
 */
export const recoverUserPassword = (state: RootState, email: string) => {
	return doRecoverUserPassword(email)
		.then(() => authenticateUser(state))
}

/**
 * Underlying function to send a recovery email to a user
 *
 * @param email
 */
export const doRecoverUserPassword = (email: string) => {
	return new Promise((resolve: any, reject: any) => {
		http.post(RESET_PASSWORD_API, { email})
			.then(response => resolve(response.data))
			.catch(error => reject(error))
	})
}