import * as React from 'preact'

import { ActionTypeStates } from '../constants/action-types'
import Spinner from './Spinner'

interface LoadingButtonProps {
	status: ActionTypeStates
	type?: string
	className?: string
	disabled?: boolean
	onClick?: any
}

export default class LoadingButton extends React.Component<LoadingButtonProps, {}> {
	public render() {
		return <div className={`loading-button ${this.props.status === ActionTypeStates.INPROGRESS ? 'is-loading': ''}`}>
			<button class="btn btn-default" {...this.props}>
				{
					this.props.status === ActionTypeStates.INPROGRESS &&
						<div className="button__spinner"><Spinner/></div>
				}
				{ this.props.children }
			</button>
		</div>
	}
}