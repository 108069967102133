import * as React from 'preact'
import { connect } from "unistore/preact"
import Link from '../components/Link'
import Icon from '../components/Icon'
import { RootState } from '../store'
import { State as AuthenticateState } from '../store/authenticate'
import { ActionTypeStates } from '../constants/action-types'

interface DashboardToolbarProps {
	authenticateState: AuthenticateState
}

class DashboardToolbar extends React.Component<DashboardToolbarProps, {}> {
	public render() {
		const { authenticateState } = this.props
		
		console.log('authenticateState.user', authenticateState.user)
		
		return (
			<div className="dashboard-toolbar">
				<ul className="dashboard-toolbar__menu">
					<li>
						<Link href="/dashboard" activeClassName="active">
							<Icon name="dashboard"/>
							<label>Dashboard</label>
						</Link>
					</li>
					<li>
						<Link href="/dashboard/activity">
							<Icon name="activity"/>
							<label>Activity</label>
						</Link>
					</li>
					<li>
						<Link href="/dashboard/schedule">
							<Icon name="calendar"/>
							<label>Schedule</label>
						</Link>
					</li>
					<li>
						<Link href="/dashboard/billing">
							<Icon name="billing"/>
							<label>Billing</label>
						</Link>
					</li>
					<li>
						<Link href="/dashboard/team">
							<Icon name="team"/>
							<label>Team</label>
						</Link>
					</li>
					<li>
						<Link href="/dashboard/insights">
							<Icon name="report"/>
							<label>Insights</label>
						</Link>
					</li>
				</ul>
				
				{
					(authenticateState.status !== ActionTypeStates.INPROGRESS && authenticateState.user) && (
						<ul className="dashboard-toolbar__menu dashboard-toolbar__menu--bottom">
							<li className="dashboard-toolbar__user-thumbnail">
								<Link href="/dashboard/my-profile">
									<img src={authenticateState.user.thumbnailImageUrl} alt={authenticateState.user.name}/>
									<label>Profile</label>
								</Link>
							</li>
						</ul>
					)
				}
			</div>
		)
	}
}


const mapStateToProps = (state: RootState) => ({
	authenticateState: state.authenticate
})

export default connect(mapStateToProps, {})(DashboardToolbar)