import { ActionTypeStates } from '../constants/action-types'
import { UserRecord } from '../interfaces/user'

export interface State {
	status: ActionTypeStates
	user: UserRecord
	redirectTo: string
	error: any
}

export const initialState: State = {
	status: ActionTypeStates.NONE,
	user: null,
	redirectTo: null,
	error: null
}