import * as React from 'preact'
import Header from './Header'
import Footer from './Footer'

interface BasePublicProps {}

class BasePublic extends React.Component<BasePublicProps, {}> {
	public render() {
		const { children } = this.props
		
		return (
			<div className="page">
				<Header/>
					{children}
				<Footer/>
			</div>
		)
	}
}

export default BasePublic