import * as React from 'preact'
import Link from '../components/Link'

interface StickyFooterProps {}

class StickyFooter extends React.Component<StickyFooterProps, {}> {
	public render() {
		return (
			<footer className="sticky-footer">
				<ul className="sticky-footer__menu">
					<li>© {new Date().getFullYear()} Urban Founders Co, Ltd.</li>
					<li><a target="_blank" rel="nofollow" href="https://support.urbanfounders.com">Support</a></li>
					<li><Link href="/terms-of-service">Terms of Service</Link></li>
					<li><Link href="/privacy-policy">Privacy Policy</Link></li>
				</ul>
			</footer>
		)
	}
}

export default StickyFooter