import * as React from 'preact'

class PlatformFeatures extends React.Component<{}, {}> {
	public render() {
		return (
			<div className="content-section">
				<div className="container">
					<div className="w-layout-grid content-feature">
						<div className="grid-wrapper">
							<div className="w-layout-grid feature-grid">
								<div className="feature-block"><img src="/images/uf-feature-2.svg" alt="" className="feature-icon"/>
									<h4>Network of<br/>Professionals</h4>
								</div>
								<div className="feature-block feature-02"><img src="/images/uf-feature-3.svg" alt="" className="feature-icon"/>
									<h4>Secure<br/>Payments</h4>
								</div>
								<div className="feature-block"><img src="/images/uf-feature-1.svg" alt="" className="feature-icon"/>
									<h4>Digital<br/>Contracts</h4>
								</div>
								<div className="feature-block feature-02"><img src="/images/uf-feature-4.svg" alt="" className="feature-icon"/>
									<h4>Knowledge Base<br/>& Tools</h4>
								</div>
							</div>
							<div className="bg-element bg-features"/>
						</div>
						<div className="content-text-wrapper">
							<h1 className="content-h1">All in one Platform</h1>
							<p className="paragraph">Through the Urban Founders platform we provide a suite of cutting-edge tools &
								services accompanied by a community curated knowledge base for our members.</p>
							<a href="/signup" className="link-arrow w-inline-block">
								<div className="arrow-text">See all features</div><img src="/images/5e7adffc6e755a3d0ea7d53e_arow.svg" alt="" className="arrow"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PlatformFeatures