import * as React from 'preact'
import { connect } from "unistore/preact"

import BaseSignup from '../containers/BaseSignup'
import { ActionTypeStates } from '../constants/action-types'

import { RootState } from '../store'
import { State as AuthenticateUserState } from '../store/authenticate'
import { State as ForgotPasswordDataState } from '../store/forgotPassword'
import { State as ResetPasswordDataState } from '../store/resetPassword'

import { passwordValidation, PasswordValidation } from '../utils/validation'
import { recoverUserPassword, resetUserPassword } from '../actions/auth'
import RecoverPasswordForm from '../containers/RecoverPasswordForm'

interface ForgotPasswordProps {
	authenticateState: AuthenticateUserState,
	forgotPasswordState: ForgotPasswordDataState,
	resetPasswordState: ResetPasswordDataState
	recoverUserPassword: any
	resetUserPassword: any
}

interface ForgotPasswordState {
	email: string
	password: string
	passwordRepeat: string
	passwordValidation: PasswordValidation
	isValid: boolean
}

class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
	public render() {
		const { forgotPasswordState, resetPasswordState } = this.props
		const isResetting = false
		
		return (
			<div class="page page-signup">
				<div className="page-signup__form__wrap">
					<BaseSignup>
						{
							!isResetting && forgotPasswordState.status !== ActionTypeStates.SUCCESS && (
								this.renderPasswordRecover()
							)
						}
						{
							!isResetting && forgotPasswordState.status === ActionTypeStates.SUCCESS && (
								this.renderPasswordRecoverSuccessMessage()
							)
						}
						{
							isResetting && resetPasswordState.status !== ActionTypeStates.SUCCESS && (
								this.renderPasswordReset()
							)
						}
						{
							isResetting && resetPasswordState.status === ActionTypeStates.SUCCESS && (
								this.renderPasswordResetSuccessMessage()
							)
						}
					</BaseSignup>
				</div>
				
				<div className="page-signup__graphics__wrap" style="background-image: url(/images/signup-illustration.svg);">
				
				</div>
			</div>
		)
	}
	
	private validateForm(modifiedState?: any) {
		const newState: ForgotPasswordState = { ...this.state, ...modifiedState }
		const { password, passwordRepeat } = newState
		
		newState.passwordValidation = passwordValidation(password, passwordRepeat)
		newState.isValid = newState.passwordValidation >= PasswordValidation.WEAK && passwordRepeat.length > 0
		
		this.setState(newState)
	}
	
	private renderPasswordRecover() {
		return <RecoverPasswordForm/>
	}
	
	private renderPasswordRecoverSuccessMessage() {
		return <div>Check your email</div>
	}
	
	private renderPasswordReset() {
		return <div>Enter your new password</div>
	}
	
	private renderPasswordResetSuccessMessage() {
		return <div>Password Updated, login now</div>
	}
	
	private submitPasswordRecover() {
		const { email } = this.state
		this.props.recoverUserPassword(this.state.email)
	}
	
	private submitPasswordReset() {
		const { email, password } = this.state
		const token = ''
		
		if (token) {
			this.props.recoverUserPassword(token, email, password)
		}
	}
}

const mapStateToProps = (state: RootState) => ({
	authenticateState: state.authenticate,
	forgotPasswordState: state.forgotPassword,
	resetPasswordState: state.resetPassword
})

export default connect(mapStateToProps, {
	resetUserPassword,
	recoverUserPassword
})(ForgotPassword)