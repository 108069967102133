import * as React from 'preact'

export enum InputType {
	TEXT = 'text',
	NUMBER = 'number',
	TEXTAREA = 'textarea',
	EMAIL = 'email',
	PASSWORD = 'password'
}

interface InputState {
	isPristine: boolean
	isMissing: boolean
	isInvalid: boolean
}

interface InputProps {
	name: string
	value: any
	type: InputType
	required?: boolean
	placeholder?: string
	prefix?: string
	label?: string
	description?: string
	span?: number
	options?: any
	multi?: boolean
	simpleValue?: boolean
	onChange?: any
	onFocusLost?: any
	disabled?: boolean
}

export default class Input extends React.Component<InputProps, InputState> {
	public constructor(props: InputProps) {
		super(props)
		
		this.state = {
			isPristine: true,
			isMissing: false,
			isInvalid: false
		}
	}
	
	public render() {
		const {name, placeholder, type, value, span, label, description, disabled} = this.props
		const {isInvalid, isMissing} = this.state
		
		return (
			<div className={'input ' + (span ? 'span-' + span : 'span-1')}>
				{
					label && <label>{label}</label>
				}
				{
					description && <small>{description}</small>
				}
				{
					type === InputType.TEXTAREA && (
						<textarea
							className="form-input text-field w-input"
							name={name}
							placeholder={placeholder}
							value={value ? value : ''}
							onInput={(e: any) => {
								if (this.props.onChange) {
									this.props.onChange(e.target.value)
								}
								this.performRequiredValidation(e.target.value)
							}}
							onBlur={() => {
								if (this.props.onFocusLost) {
									this.props.onFocusLost()
								}
								this.performRequiredValidation(value)
							}}/>
					)
				}
				{
					(type === InputType.TEXT || type === InputType.EMAIL ||
						type === InputType.NUMBER || type === InputType.PASSWORD) && (
						<input
							className="form-input text-field w-input"
							name={name}
							readOnly={disabled}
							placeholder={placeholder}
							type={type}
							value={value}
							onInput={(e: any) => {
								if (this.props.onChange) {
									this.props.onChange(e.target.value)
								}
								this.performRequiredValidation(e.target.value)
							}}
							onBlur={() => {
								if (this.props.onFocusLost) {
									this.props.onFocusLost()
								}
								this.performRequiredValidation(value)
							}}/>
					)
				}
				{
					isMissing && (
						<span className="input-error">This field is required</span>
					)
				}
				{
					isInvalid && (
						<span className="input-error">This field is incorrect</span>
					)
				}
			</div>
		)
	}
	
	private performRequiredValidation(value: any) {
		const {required} = this.props
		const {isPristine} = this.state
		
		if (!required) {
			return
		}
		
		if (isPristine) {
			this.setState({isPristine: false})
		} else if (!isPristine && value.length === 0) {
			this.setState({isMissing: true})
		} else {
			this.setState({isMissing: false})
		}
	}
}