import * as React from 'preact'
import BasePublic from '../containers/BasePublic'

class NotFound extends React.Component<{}, {}> {
	public render() {
		return (
			<BasePublic>
				404 NotFound
			</BasePublic>
		)
	}
}

export default NotFound