import * as React from 'preact'

interface MailchimpSubscribeProps {}

class MailchimpSubscribe extends React.Component<MailchimpSubscribeProps, {}> {
	public render() {
		return (
			<div className="page">
				<form
					id="email-form"
					name="email-form"
					data-name="Email Form"
					className="form"
					action="https://urbanfounders.us19.list-manage.com/subscribe/post">
					<input
						type="email"
						name="EMAIL"
						className="text-field-find w-input"
						placeholder="Enter your email"/>
					<input type="submit" value="Subscribe" data-wait="Please wait..." className="subscribe-button w-button"/>
					<input type="hidden" name="u" value="7ea8d9ce835a268bada775d6b"/>
					<input type="hidden" name="id" value="e452f2bbcf"/>
				</form>
			</div>
		)
	}
}

export default MailchimpSubscribe