export const API = process.env.API_URL || 'https://api.urbanfounders.com'

export const AUTH_API = `${API}/auth`
export const REGISTER_API = `${API}/auth/register`
export const LOGIN_API = `${API}/auth/login`
export const RECOVER_PASSWORD_API = `${API}/auth/forgot`
export const RESET_PASSWORD_API = `${API}/auth/recover`
export const CONFIRM_EMAIL_API = `${API}/auth/confirm-email`

export const LINKEDIN_API_KEY = process.env.LINKEDIN_API_KEY
export const LINKEDIN_API_REDIRECT = process.env.LINKEDIN_API_REDIRECT ||`${API}/auth/linkedin`