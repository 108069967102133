import * as React from 'preact'
import { connect } from 'unistore/preact'

import LinkedinLogin from './LinkedinLogin'
import Input, { InputType } from '../components/Input'
import PasswordInput, { PasswordInputType } from '../components/PasswordInput'

import { RootState } from '../store'
import { State as AuthenticateUserState } from '../store/authenticate'
import { registerUser } from '../actions/auth'
import Link from '../components/Link'

export interface RecoverPasswordFormProps {
	emailPrefill?: string
	authenticateState: AuthenticateUserState
	registerUser: any
}

export interface RecoverPasswordFormState {
	name: string
	email: string
	password: string
}

class RecoverPasswordForm extends React.Component<RecoverPasswordFormProps, RecoverPasswordFormState> {
	public constructor(props: RecoverPasswordFormProps) {
		super(props)
		
		this.state = {
			name: '',
			email: props.emailPrefill || '',
			password: ''
		}
	}
	
	public render() {
		const { name, email, password } = this.state
		
		return (
			<div className="register-form">
				<div className="register-form__inner">
					<div className="register-form__title">
						<h2>Forgot your password ?</h2>
					</div>
					
					<div className="register-form__subtitle">
						Don't worry, we all forget sometimes. <br/>Enter your email below to begin recovering your password.
					</div>
					
					<div className="register-form__form">
						<form action="#" onSubmitCapture={this.submitForm.bind(this)} formNoValidate={true}>
							<Input
								placeholder="Enter your email"
								name="email"
								value={email}
								type={InputType.EMAIL}
								onChange={(v: string) => this.validateForm({ email: v })}/>
							
							<div className="register-form__submit__wrap">
								<div className="register-form__submit">
									<button type="submit" className="button-submit w-button">Recover</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
	
	private validateForm(modifiedState?: any) {
		const {email, password} = this.state
		this.setState({...this.state, ...modifiedState, isValid: (email && password)})
	}
	
	private submitForm(e: Event) {
		const { email, password } = this.state
		
		e.preventDefault()
		this.props.registerUser(email, password)
	}
}

const mapStateToProps = (state: RootState) => ({
	authenticateState: state.authenticate
})

export default connect(mapStateToProps, {
	registerUser
})(RecoverPasswordForm)