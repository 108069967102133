import * as React from 'preact'

interface SpinnerProps {}

export default class Spinner extends React.Component<SpinnerProps, {}> {
	public render() {
		return (
			<div class="spinner spinner-donut"/>
		)
	}
}