import * as React from 'preact'

interface StepsInstructionProps {}

export default class StepsInstruction extends React.Component<StepsInstructionProps, {}> {
	public render() {
		return (
			<div class="works-section">
				<div class="container">
					<div class="step-wrapper">
						<div class="title-wrap">
							<h1>How it works</h1>
						</div>
						<div class="step">
							<div class="w-layout-grid grid-works">
								<div class="content-wrap">
									<h2 class="content-h1">Pick the type of tool you would like</h2>
									<p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium, elit quis vehicula.</p>
								</div>
								<div class="counter-wrap">
									<div class="stop-counter">
										<div>1</div>
									</div>
								</div>
								<div class="works-image-wrap"><img src="/images/5e7adffc6e755a05a5a7d5f7_Step%25201%2520Graphic.svg" alt=""/></div>
							</div>
							<div class="bg-step"/>
						</div>
						<div class="step">
							<div class="w-layout-grid grid-works">
								<div class="works-image-wrap"><img src="/images/5e7adffc6e755a9928a7d5f6_Step%25202%2520Graphic.svg" alt=""/></div>
								<div class="counter-wrap">
									<div class="stop-counter purple-step">
										<div>2</div>
									</div>
								</div>
								<div class="content-wrap">
									<h2 class="content-h1">Integrate the tool into your website or social platforms</h2>
									<p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium, elit quis vehicula.</p>
								</div>
							</div>
							<div class="bg-step bg-step-02"/>
						</div>
						<div class="step">
							<div class="w-layout-grid grid-works">
								<div class="content-wrap">
									<h2 class="content-h1">Monitor analytics &amp; collect engagement</h2>
									<p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium, elit quis vehicula.</p>
								</div>
								<div class="counter-wrap">
									<div class="stop-counter blue-step">
										<div>3</div>
									</div>
								</div>
								<div class="works-image-wrap"><img src="/images/5e7adffc6e755a2effa7d5f5_Step%25203%2520Graphic.svg" alt=""/></div>
							</div>
							<div class="bg-step bg-step-03"/>
						</div>
						<div class="line"/>
					</div>
				</div>
			</div>
		)
	}
}