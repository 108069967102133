import * as React from 'preact'

class ClientLogos extends React.Component<{}, {}> {
	public render() {
		return (
			<div className="logo-section">
				<div className="container">
					<div style="opacity: 1;" className="w-layout-grid logo-grid">
						<div className="logo-block"><img src="/images/alpha-partners.png" alt="" className="logo" width="143"/></div>
						<div className="logo-block"><img src="/images/bevplus.png" alt="" className="logo" width="143"/></div>
						<div className="logo-block"><img src="/images/hubbers.png" alt="" className="logo" width="123"/></div>
					</div>
				</div>
			</div>
		)
	}
}

export default ClientLogos