import * as React from 'preact'
import { connect } from 'unistore/preact'

import Input, { InputType } from '../components/Input'
import PasswordInput, { PasswordInputType } from '../components/PasswordInput'

import { RootState } from '../store'
import { State as RegisterState } from '../store/register'
import { registerUser } from '../actions/auth'
import Link from '../components/Link'
import LoadingButton from '../components/LoadingButton'

export interface RegisterFormProps {
	emailPrefill?: string
	registerState: RegisterState
	registerUser: any
}

export interface RegisterFormState {
	name: string
	email: string
	password: string
}

class RegisterForm extends React.Component<RegisterFormProps, RegisterFormState> {
	public constructor(props: RegisterFormProps) {
		super(props)
		
		this.state = {
			name: '',
			email: props.emailPrefill || '',
			password: ''
		}
	}
	
	public render() {
		const { registerState } = this.props
		const { name, email, password } = this.state
		
		return (
			<div className="register-form">
				<div className="register-form__inner">
					<div className="register-form__title">
						<h2>Create your account</h2>
					</div>
					
					<div className="register-form__form">
						<form action="#" onSubmitCapture={this.submitForm.bind(this)} formNoValidate={true}>
							<Input
								placeholder="Enter your name"
								name="name"
								value={name}
								type={InputType.TEXT}
								onChange={(v: string) => this.validateForm({ name: v })}/>
							
							<Input
								placeholder="Enter your email"
								name="email"
								value={email}
								type={InputType.EMAIL}
								onChange={(v: string) => this.validateForm({ email: v })}/>
							
							<PasswordInput
								placeholder="Enter your password"
								name="password"
								value={password}
								type={PasswordInputType.VALIDATE}
								onChange={(v: string) => this.validateForm({ password: v })}/>
							
							<div className="register-form__submit__wrap">
								<div className="register-form__submit">
									<LoadingButton
										status={registerState.status}
										type="submit"
										className="button-submit w-button">
										Register
									</LoadingButton>
								</div>
								<div className="register-form__forgot-password">
									<Link href="/forgot-password">Forgot Password ?</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
	
	private validateForm(modifiedState?: any) {
		const {email, password} = this.state
		this.setState({...this.state, ...modifiedState, isValid: (email && password)})
	}
	
	private submitForm(e: Event) {
		const { email, password } = this.state
		
		e.preventDefault()
		this.props.registerUser(email, password)
	}
}

const mapStateToProps = (state: RootState) => ({
	registerState: state.register
})

export default connect(mapStateToProps, {
	registerUser
})(RegisterForm)