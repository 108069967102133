import * as React from 'preact'
import Link from '../components/Link'
import Icon from '../components/Icon'

interface DashboardHeaderProps {}

class DashboardHeader extends React.Component<DashboardHeaderProps, {}> {
	public render() {
		return (
			<div className="dashboard-header">
				<div className="dashboard-header__favicon">
					<Link href="/dashboard" aria-current="page">
						<img src="/images/uf-logo.svg" alt=""/>
					</Link>
				</div>
				
				<div className="dashboard-header__menu">
					<ul>
						<li className="active">Hubbers</li>
						<li>Urban Founders</li>
						
						<li className="dashboard-header__menu__item dashboard-header__menu--new-project">New Project</li>
					</ul>
				</div>
				
				<div className="dashboard-header__notifications">
					<ul>
						<li><Icon name="bell"/></li>
						<li><Icon name="comments"/></li>
					</ul>
				</div>
			</div>
		)
	}
}

export default DashboardHeader