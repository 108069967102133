import * as React from 'preact'

interface CalendlyLinkProps {
	link: string
}

export default class CalendlyLink extends React.Component<CalendlyLinkProps, {}> {
	public componentDidMount() {
		const head = document.querySelector('head')
		const script = document.createElement('script')
		const style = document.createElement('link')
		style.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css')
		style.setAttribute('rel', 'stylesheet')
		script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js')
		head.appendChild(style)
		head.appendChild(script)
	}
	
	public openCalendly(e: Event) {
		const { link } = this.props
		
		e.preventDefault()
		Calendly.initPopupWidget({ url: link })
		
		return false;
	}
	
	public render() {
		return (
			<div onClick={this.openCalendly.bind(this)}>{this.props.children}</div>
		)
	}
}