import * as React from 'preact'

interface HeroDefaultProps {
	title: string
	subtitle?: string
}

class HeroDefault extends React.Component<HeroDefaultProps, {}> {
	public render() {
		const { title, subtitle } = this.props
		
		return (
			<div class="hero-default">
				<div class="container">
					<div class="title-centre">
						<h1 class="display-heading">{title}</h1>
						{ subtitle && <div class="hero-details">{subtitle}</div> }
					</div>
				</div>
			</div>
		)
	}
}

export default HeroDefault