import * as React from 'preact'
import { connect } from 'unistore/preact'

import { RootState } from '../store'
import { State as AuthenticateUserState } from '../store/authenticate'
import { authenticateUser } from '../actions/auth'

interface AppProps {
	authenticateState: AuthenticateUserState
	authenticateUser: any
}

class App extends React.Component<AppProps, {}> {
	public componentDidMount() {
		this.props.authenticateUser()
	}
	
	public render() {
		return this.props.children
	}
}

const mapStateToProps = (state: RootState) => ({
	authenticateState: state.authenticate
})

export default connect(mapStateToProps, {
	authenticateUser
})(App)