import * as React from 'preact'

import HeroInput from './HeroInput'

class HeroContact extends React.Component<{}, {}> {
	public render() {
		return (
			<div class="hero-contact">
				<div class="container">
					<div class="title-left">
						<h1 class="display-heading">Get in touch with us</h1>
						<div class="hero-details">We'd love to hear from you, fill the form below and we'll get back to you within 2 working days.</div>
					</div>
				</div>
			</div>
		)
	}
}

export default HeroContact