import * as React from 'preact'
import { Link as RouterLink } from 'preact-router/match'

interface LinkProps extends React.JSX.HTMLAttributes {
	activeClassName?: string;
	children?: React.ComponentChildren;
}

export default class Link extends React.Component<LinkProps, {}> {
	public render(): any {
		const { activeClassName, children } = this.props
		return <RouterLink {...this.props} activeClassName={activeClassName || 'active'}>{children}</RouterLink>
	}
}