import * as React from 'preact'
import Link from '../components/Link'

interface StickyHeaderProps {}

class StickyHeader extends React.Component<StickyHeaderProps, {}> {
	public render() {
		return (
			<div className="sticky-header navbar">
				<Link href="/" aria-current="page" className="brand w-nav-brand w--current">
					<img src="/images/uf-logo.svg" alt=""/>
				</Link>
				
				<Link className="right-link" href="/signin">Sign In</Link>
			</div>
		)
	}
}

export default StickyHeader