import * as React from 'preact'

interface SpotlightProps {
	orientation: SpotlightOrientation
	title: string
	subtitle: string
	featuredImage: string
	ctaText?: string
	ctaLink?: string
	sectionClasses?: string
	bgClasses?: string
}

export enum SpotlightOrientation {
	IMAGE_LEFT_TEXT_RIGHT,
	IMAGE_RIGHT_TEXT_LEFT
}

class Spotlight extends React.Component<SpotlightProps, {}> {
	public render() {
		const { orientation, title, subtitle, featuredImage, ctaText, ctaLink, sectionClasses, bgClasses } = this.props
		
		return (
			<div className="content-section">
				<div className="container">
					<div className="bg-container">
						<div className={"w-layout-grid " + sectionClasses}>
							{
								orientation === SpotlightOrientation.IMAGE_LEFT_TEXT_RIGHT && (
									<div className="content-image-wrapper"><img src={featuredImage} alt="" className="content-image"/></div>
								)
							}
							<div className="content-text-wrapper">
								<h1 className="content-h1" dangerouslySetInnerHTML={{ __html: title }}/>
								<p className="paragraph" dangerouslySetInnerHTML={{ __html: subtitle }}/>
								{
									ctaText && (
										<a href={ctaLink} className="link-arrow w-inline-block">
											<div className="arrow-text">{ctaText}</div><img src="/images/5e7adffc6e755a3d0ea7d53e_arow.svg" alt="" className="arrow"/>
										</a>
									)
								}
							</div>
							{
								orientation === SpotlightOrientation.IMAGE_RIGHT_TEXT_LEFT && (
									<div className="content-image-wrapper">
										<img src={featuredImage} alt="" className="content-image analytics"/>
										<div className="bg-element bg-yellow"/>
									</div>
								)
							}
						</div>
						{
							orientation === SpotlightOrientation.IMAGE_LEFT_TEXT_RIGHT && (
								<div className="bg-element bg-content"/>
							)
						}
					</div>
				</div>
			</div>
		)
	}
}

export default Spotlight