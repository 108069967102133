import * as React from 'preact'
import { route } from 'preact-router'

interface RedirectProps {
	to: string
}

export default class Redirect extends React.Component<RedirectProps, {}> {
	public componentWillMount() {
		route(this.props.to, true)
	}
	
	public render(): any {
		return null
	}
}