import * as React from 'preact'

import HeroInput from './HeroInput'

class Hero extends React.Component<{}, {}> {
	public render() {
		return (
			<div className="hero">
				<div className="container">
					<div className="title-centre">
						<h1 className="display-heading">CxO as a Service</h1>
						<div className="hero-details">We bring an entire executive department to your startup <br/> on a Pay-as-you-Grow model.</div>
					</div>
					<div className="button-wrapper">
						<HeroInput/>
					</div>
				</div>
				<div className="dashboard-wrapper">
					<img src="/images/header-graphic-1.svg" alt="" className="dashboard"/>
					<img src="/images/uf-header-one-touch.svg" alt="" className="dashboard-card-02"/>
					<img src="/images/uf-homepage-header-2.png" alt="" className="dashboard-card-01"/>
				</div>
			</div>
		)
	}
}

export default Hero