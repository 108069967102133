import * as React from 'preact'
import Link from '../components/Link'
import MailchimpSubscribe from './MailchimpSubscribe'

interface FooterProps {}

class Footer extends React.Component<FooterProps, {}> {
	public render() {
		return (
			<div>
				<div className="footer">
					<div className="container">
						<div className="w-layout-grid footer-grid">
							<div id="w-node-7dc5b5ee9d91-b8f5df7e" className="footer-column">
								<a href="/" aria-current="page" className="footer-logo-link w-inline-block w--current">
									<img src="/images/uf-favicon.svg" alt="" className="footer-logo"/>
								</a>
							</div>
							<div className="footer-column">
								<div className="title-grey">About Us</div>
								<Link href="/" aria-current="page" className="footer-link w--current">Home</Link>
								{/*<Link disabled href="/platform" className="footer-link">Platform</Link>*/}
								<a href="https://angel.co/urban-founders" target="_blank" rel="nofollow" className="footer-link">Company</a>
								<a target="_blank" href="https://consulting.urbanfounders.com" className="footer-link">Consulting</a>
								{/*<a target="_blank" href="https://blog.urbanfounders.com" className="footer-link">Blog</a>*/}
								{/*<Link href="/memberships" className="footer-link">Memberships</Link>*/}
							</div>
							<div className="footer-column">
								<div className="title-grey">Resources</div>
								<a href="https://urbanfounders.zendesk.com/hc/en-us/categories/360003743853-Getting-Started" target="_blank" className="footer-link">Getting Started</a>
								<a href="https://urbanfounders.zendesk.com/hc/en-us/articles/360047633933-Join-as-a-CMO" target="_blank" className="footer-link">Become a CTO</a>
								<a href="https://urbanfounders.zendesk.com/hc/en-us/articles/360046983694-Join-as-a-CTO" target="_blank" className="footer-link">Become a CMO</a>
								{/*<a href="https://urbanfounders.typeform.com/to/WwGyJS" target="_blank" className="footer-link">Co-Create with Us</a>*/}
							</div>
							<div className="footer-column">
								<div className="title-grey">Support</div>
								<Link href="/contact" className="footer-link">Contact</Link>
								<a target="_blank" rel="nofollow" href="https://urbanfounders.zendesk.com/hc/en-us" className="footer-link">Support</a>
								<Link href="/terms-of-service" className="footer-link">Terms of Service</Link>
								<Link href="/privacy-policy" className="footer-link">Privacy Policy</Link>
							</div>
							<div id="w-node-b337b8f5dfa3-b8f5df7e" className="footer-column">
								<div className="title-grey">SUBSCRIBE TO OUR NEWSLETTER</div>
								<div className="footer-text">The latest news, articles, and resources, sent to<br/>your inbox weekly.</div>
								<div className="form-block">
									<MailchimpSubscribe/>
								</div>
								<div className="form-block w-form">
									<div className="success-message w-form-done">
										<div>Thank you! Your submission has been received!</div>
									</div>
									<div className="error-message w-form-fail">
										<div>Oops! Something went wrong while submitting the form.</div>
									</div>
								</div>
							</div>
						</div>
						<div className="legal-block">
							<div className="legal-text">© {new Date().getFullYear()} Urban Founders Co, Ltd.</div>
							<div className="social-links">
								<a href="https://angel.co/urban-founders" target="_blank" rel="nofollow" className="social-link w-inline-block">
									<span className="icon icon-angellist"/>
								</a>
								<a href="https://www.linkedin.com/company/urban-founders" target="_blank" rel="nofollow" className="social-link w-inline-block">
									<span className="icon icon-linkedin"/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Footer