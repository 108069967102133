import * as React from 'preact'
import { connect } from 'unistore/preact'

import Link from '../components/Link'

import { RootState } from '../store'
import { State as AuthenticateState } from '../store/authenticate'
import { ActionTypeStates } from '../constants/action-types'
import Tooltip from '../components/Tooltip'

interface HeaderProps {
	authenticateState: AuthenticateState
}

interface HeaderState {
	isNavOpen: boolean
}

class Header extends React.Component<HeaderProps, HeaderState> {
	public render() {
		const { authenticateState } = this.props
		const { isNavOpen } = this.state
		
		return (
			<div id="Navbar" className={'navbar w-nav ' + (isNavOpen ? 'nav--open' : '')}>
				<div className="navigation-container">
					<div className="navigation-left">
						<Link href="/" aria-current="page" className="brand w-nav-brand w--current">
							<img src="/images/uf-logo.svg" alt=""/>
						</Link>
					</div>
					<nav role="navigation" className="nav-menu w-nav-menu">
						<Link href="/" className="nav-link w-nav-link"><span>Home</span></Link>
						<Tooltip text="In Beta">
							<Link disabled href="/platform" className="nav-link w-nav-link"><span>Platform</span></Link>
						</Tooltip>
						{/*<Link href="/company" className="nav-link w-nav-link"><span>Company</span></Link>*/}
						<Tooltip text="Coming Soon">
							<a disabled className="nav-link w-nav-link"><span>Memberships</span></a>
						</Tooltip>
						<Link href="/contact" className="nav-link w-nav-link"><span>Contact</span></Link>
					</nav>
					<div className="navigation-right">
						{
							(authenticateState.status !== ActionTypeStates.INPROGRESS && !authenticateState.user) && (
								<div className="div-block-3">
									<Link href="/signin" className="nav-link w-nav-link"><span>Sign In</span></Link>
									<a href="/signup" className="btn btn-nav">Join Now</a>
								</div>
							)
						}
						{
							(authenticateState.status !== ActionTypeStates.INPROGRESS && authenticateState.user) && (
								<div className="div-block-3">
									<Link href="/dashboard" className="nav-link w-nav-link"><span>My Dashboard</span></Link>
								</div>
							)
						}
						<div class="header--toggle header__navigation--toggle" onClick={this.toggleMenu.bind(this)}>
							<button className={'hamburger hamburger--collapse hamburger--squeeze ' + (isNavOpen ? 'is-active' : '')} type="button">
		            <span class="hamburger-box">
		              <span class="hamburger-inner"/>
		            </span>
							</button>
						</div>
					</div>
				</div>
				<div className="w-nav-overlay"/>
			</div>
		)
	}
	
	private toggleMenu() {
		const { isNavOpen } = this.state
		
		this.setState({ isNavOpen: !isNavOpen })
	}
}

const mapStateToProps = (state: RootState) => ({
	authenticateState: state.authenticate
})

export default connect(mapStateToProps, {})(Header)