import * as React from 'preact'
import BaseDashboard from '../containers/BaseDashboard'

class DashboardInsights extends React.Component<{}, {}> {
	public render() {
		return (
			<BaseDashboard>
				Dashboard Insights
			</BaseDashboard>
		)
	}
}

export default DashboardInsights