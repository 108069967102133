import * as React from 'preact'
import { route } from 'preact-router'
import { parse as parseQueryString } from 'querystring'

import LinkedinLogin from '../containers/LinkedinLogin'

interface HeroInputState {
	email: string
}

class HeroInput extends React.Component<{}, HeroInputState> {
	public render() {
		return (
			<div className="hero-input">
				<form className="email-form">
					<div className="input-wrapper">
						<input
							placeholder="Enter your email"
							type="email"
							name="email"
							required={true}
							className="input text-ellipsis"
							value={this.state.email}
							onInput={(e: any) => {
								this.setState({ email: e.target.value })
							}}/>
						<button type="submit" className="btn link-arrow" onClick={this.submitRegister.bind(this)}>
							<div className="arrow-text">Get Started</div>
							<img src="/images/5e7adffc6e755a3d0ea7d53e_arow.svg" alt="" className="arrow"/>
						</button>
					</div>
				</form>
				{/*<div className="linkedin-wrapper">*/}
				{/*	<div className="details">or continue with</div>*/}
				{/*	<div className="social-login__wrap">*/}
				{/*		<LinkedinLogin*/}
				{/*			text="Linkedin"/>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		)
	}
	
	private submitRegister(e: Event) {
		e.preventDefault()
		
		const { email } = this.state
		route(`/signup?email=${email}`, false)
	}
}

export default HeroInput