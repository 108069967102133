import * as React from 'preact'
import { connect } from "unistore/preact"

import BaseSignup from '../containers/BaseSignup'
import RegisterForm from '../containers/RegisterForm'

import { ActionTypeStates } from '../constants/action-types'
import { RootState } from '../store'
import { State as RegisterDataState } from '../store/register'
import { State as AuthenticateUserState } from '../store/authenticate'
import { parse as parseQueryString } from "querystring"


interface RegisterProps {
	authenticateState: AuthenticateUserState
	registerState: RegisterDataState
}

class Register extends React.Component<RegisterProps, {}> {
	private emailPrefill: string
	
	public constructor(props: RegisterProps) {
		super(props)
		
		const params = parseQueryString(window.location.search.replace('?', ''))
		this.emailPrefill = params.email as string || ''
	}
	
	public componentDidMount() {
		document.title = "Sign Up | Urban Founders"
	}
	
	public render() {
		const { registerState } = this.props
		
		return (
			<div class="page page-signup">
				<div className="page-signup__form__wrap">
					<BaseSignup>
						{
							registerState.status !== ActionTypeStates.SUCCESS && (
								// @ts-ignore
								<RegisterForm emailPrefill={this.emailPrefill} />
							)
						}
						{
							registerState.status === ActionTypeStates.SUCCESS && (
								<div className="register__email-validation">
									<div className="register__email-validation__graphic">
									</div>
									<div className="register__email-validation__title">
										<h2>Thank you for signing up!</h2>
									</div>
									<div className="register__email-validation__subtitle">
										<p>Urban Founder is currently in a private beta, and we're reviewing all signups personally.</p>
										<p>Once we're reviewed your signup, we'll send over an invitation link to your email!</p>
									</div>
									<div className="register__email-validation__cta">
									</div>
								</div>
							)
						}
					</BaseSignup>
				</div>
				<div className="page-signup__graphics__wrap" style="background-image: url(/images/signup-illustration.svg);">
				
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: RootState) => ({
	authenticateState: state.authenticate,
	registerState: state.register
})

export default connect(mapStateToProps, {
})(Register)