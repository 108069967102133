import * as React from 'preact'
import Link from '../components/Link'

interface DashboardActivityProps {}

class DashboardActivity extends React.Component<DashboardActivityProps, {}> {
	public render() {
		return (
			<div className="dashboard-activity">
				<div className="dashboard-activity__cxo">
					<div className="dashboard-activity__cxo__inner">
						<div className="dashboard-activity__cxo__name__wrap">
							<div className="dashboard-activity__cxo__name">Udit Veerwani</div>
							<div className="dashboard-activity__cxo__title">CTO & Project Manager</div>
						</div>
						
						<div className="dashboard-activity__cxo__thumbnail">
							<div style=""/>
						</div>
					</div>
					
					<div className="dashboard-activity__cxo__analysis">
						<div className="dashboard-activity__cxo__analysis__sprints">
							2.3 sprints
							<span>every 14 days</span>
						</div>
						
						<Link href="/dashboard/activity" className="link-arrow w-inline-block">
							Activity &nbsp;
							<img src="/images/5e7adffc6e755a3d0ea7d53e_arow.svg" alt="" class="arrow"/>
						</Link>
					</div>
				</div>
				
				<div className="dashboard-activity__metrics">
					<div className="dashboard-activity__metrics__item">
						<label htmlFor="">Sprints</label>
					</div>
					<div className="dashboard-activity__metrics__item">
					
					</div>
				</div>
				
				<div className="dashboard-activity__updates">
				
				</div>
			</div>
		)
	}
}

export default DashboardActivity