import * as React from 'preact'
import BaseDashboard from '../containers/BaseDashboard'

class Dashboard extends React.Component<{}, {}> {
	public render() {
		return (
			<BaseDashboard>
				Dashboard Content
			</BaseDashboard>
		)
	}
}

export default Dashboard