import * as React from 'preact'
import BasePublic from '../containers/BasePublic'
import TypeForm from '../components/TypeForm'
import HeroContact from '../components/HeroContact'


class Contact extends React.Component<{}, {}> {
	public componentDidMount() {
		document.title = "Contact Us | Urban Founders"
	}
	
	public render() {
		return (
			<BasePublic>
				<HeroContact/>
				<div className="page page-contact">
					<TypeForm formLink="https://urbanfounders.typeform.com/to/NwyzIh"/>
				</div>
			</BasePublic>
		)
	}
}

export default Contact