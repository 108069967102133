import * as React from 'preact'
import BasePublic from '../containers/BasePublic'
import Hero from '../components/Hero'
import Spotlight, { SpotlightOrientation } from '../components/Spotlight'
import PlatformFeatures from '../components/PlatformFeatures'
import StepsInstruction from '../components/StepsInstruction'
import HeroPlatform from '../components/HeroPlatform'
import FooterCTA from '../components/FooterCTA'

class Platform extends React.Component<{}, {}> {
	public render() {
		return (
			<BasePublic>
				<HeroPlatform/>
				<Spotlight
					orientation={SpotlightOrientation.IMAGE_RIGHT_TEXT_LEFT}
					title="Exclusive network of professionals"
					subtitle="Build your exclusive professional network, starting with your collegues, "
					featuredImage="/images/professional-network.png"
					ctaText="Build your network"
					ctaLink="/platform"
					sectionClasses="content-grid-02 section-platform__professional-network"/>
				
				<Spotlight
					orientation={SpotlightOrientation.IMAGE_LEFT_TEXT_RIGHT}
					title="Digitized Agreements<br/>Secure Payments"
					subtitle="Onboard an industry leader as your CxO from Day 1"
					featuredImage="/images/5e7adffc6e755af912a7d53d_Content%2520Image.svg"
					ctaText="See it in action"
					ctaLink="/platform"
					sectionClasses="content-grid-01"/>
				
					<Spotlight
						orientation={SpotlightOrientation.IMAGE_RIGHT_TEXT_LEFT}
						title="We work with <br/>industries we know"
						subtitle="Onboard an industry leader as your CxO from Day 1"
						featuredImage="/images/5e7adffc6e755af912a7d53d_Content%2520Image.svg"
						ctaText="Get Started"
						ctaLink="/platform"
						sectionClasses="content-grid-02"/>
				
				<StepsInstruction/>
				<FooterCTA/>
			</BasePublic>
		)
	}
}

export default Platform