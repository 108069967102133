import * as React from 'preact'
import BaseDashboard from '../containers/BaseDashboard'

class Profile extends React.Component<{}, {}> {
	public render() {
		return (
			<BaseDashboard>
				Profile Page
			</BaseDashboard>
		)
	}
}

export default Profile