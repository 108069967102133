import * as React from 'preact'

interface IconProps {
	name: string
}

export default class Icon extends React.Component<IconProps, {}> {
	public render() {
		const { name } = this.props
		
		return (
			<span class={`icon icon-${name}`}/>
		)
	}
}