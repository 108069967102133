import * as React from 'preact'

interface TooltipProps {
	text: string
}

export default class Tooltip extends React.Component<TooltipProps, {}> {
	public render() {
		const { children, text } = this.props
		
		return (
			<span className="tooltip-container">
				{children}
				<div className="tooltip">{text}</div>
			</span>
		)
	}
}